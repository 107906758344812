@import "../../constants/colors.css";
@import "../../constants/layout.css";

.footer {
  position: absolute;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: var(--footer-height);
  height: var(--footer-height);
  color: var(--grey4);
}

.footer-small-text {
  font-size: 80%;
  margin-right: 8px;
}
