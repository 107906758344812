.tbody {
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.employees-container {
  width: 100%;
  height: 100%;
  margin: auto !important;
  position: relative;
}
