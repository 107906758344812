:root {
  --main: #66cc33;
  --dark-main: #369c03;
  --light-main: #b6fc83;
  --white: #fff;
  --grey1: #fafafa;
  --grey2: #f0f0f0;
  --grey3: #d9d9d9;
  --grey4: #b9b9b9;
  --grey5: #6d7278;
  --success: #52c41a;
  --warning: #faad14;
  --error: #f5222d;
}
